import {AppApi} from 'store/customer/api';
const JobSundryItems = AppApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobSundryItems: builder.mutation<
            void,
            {
                data: {
                    job_id: number;
                };
            }
        >({
            query: ({data}) => ({
                url: `/sundries/jobitems`,
                method: 'POST',
                body: {
                    ...data,
                },
            }),
        }),
    }),
});

export const {useGetJobSundryItemsMutation} = JobSundryItems;
