import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppState} from 'store/customer/storeSetup';
import {Side} from 'components/customer/BTM/entity/Side';
import {Path} from 'components/customer/BTM/entity/Path';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {BenchtopMaterial} from 'components/customer/BTM/entity/BenchtopMaterial';
import {BenchtopMaterialType} from 'components/customer/BTM/entity/BenchtopMaterialType';
import {Point} from 'components/customer/BTM/entity/Point';
import {Join, JoinOrientation} from 'components/customer/BTM/entity/Join';
import {groupBy} from 'lodash';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';
import {Edge} from 'components/customer/BTM/entity/Edge';
import {BenchtopEdgeProfile} from 'components/customer/BTM/entity/BenchtopEdgeProfile';
import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {ButtJoinPartError} from 'components/customer/BTM/entity/ButtJoinPartError';

export interface CornerError {
    index: number;
    name: string;
    x?: string;
    y?: string;
    isArc?: boolean;
}

interface BTM {
    center: number[];
    scale: number;
    type?: BenchtopType;
    materialType?: BenchtopMaterialType;
    material?: BenchtopMaterial;
    dimension?: number[];
    dimensionError?: string[];
    paths?: Path[];
    coordinates?: Point[];
    corners?: Corner[];
    cornerError?: CornerError[];
    joins?: Join[];
    thickness?: number;
    edgeProfile?: BenchtopEdgeProfile;
    variationRequest?: string;
    materialLoading: boolean;
    defaultMaterialLoaded: boolean;
    defaultEdgeProfileLoaded: boolean;
    minButtJoinPartSize?: number;
    buttJoinPartErrors: ButtJoinPartError[];
    topOffset: number;
}

interface Meta {
    index?: number;
}

const genericReducer = <T>(
    updater: (state: BTM, payload: T, meta: Meta) => void
) => ({
    reducer: (state: BTM, {payload, meta}: PayloadAction<T, string, Meta>) =>
        updater(state, payload, meta),
    prepare: (payload: T, index?: number) => ({
        payload,
        meta: {index},
    }),
});

const btmSlice = createSlice({
    name: 'btm',
    initialState: {
        cornerError: [],
        dimensionError: [],
        center: [0, 0],
        scale: 1,
        materialLoading: false,
        defaultMaterialLoaded: false,
        defaultEdgeProfileLoaded: false,
        minButtJoinPartSize: 300,
        buttJoinPartErrors: [],
        topOffset: 40,
    } as BTM,
    reducers: {
        topOffsetSet: (state, {payload}: PayloadAction<number>) => {
            state.topOffset = payload;
        },
        minButtJoinPartSizeSet: (state, {payload}: PayloadAction<number>) => {
            state.minButtJoinPartSize = payload;
        },
        cornerErrorSet: (state, {payload}: PayloadAction<CornerError>) => {
            const errorIndex = state.cornerError.findIndex(
                (error) => error.index == payload.index
            );

            if (errorIndex > -1) {
                state.cornerError.splice(errorIndex, 1);
            }

            state.cornerError.push(payload);
        },
        defaultEdgeProfileLoadedSet: (
            store,
            {payload}: PayloadAction<boolean>
        ) => {
            store.defaultEdgeProfileLoaded = payload;
        },
        defaultMaterialLoadedSet: (
            store,
            {payload}: PayloadAction<boolean>
        ) => {
            store.defaultMaterialLoaded = payload;
        },
        materialLoadingSet: (store, {payload}: PayloadAction<boolean>) => {
            store.materialLoading = payload;
        },
        variationRequestSet: (store, {payload}: PayloadAction<string>) => {
            store.variationRequest = payload;
        },
        thicknessSet: (store, {payload}: PayloadAction<number>) => {
            store.thickness = payload;
        },
        defaultThicknessSet: (store, {payload}: PayloadAction<number>) => {
            if (typeof store.thickness == 'undefined')
                store.thickness = payload;
        },
        edgeProfileSet: (
            store,
            {payload}: PayloadAction<BenchtopEdgeProfile>
        ) => {
            store.edgeProfile = payload;
        },
        joinsSet: (store, {payload}: PayloadAction<Join[]>) => {
            if (store.joins) {
                store.joins = [
                    ...store.joins.filter(
                        (join) => join.joinType === JoinType.BUTT_JOIN
                    ),
                    ...payload,
                ];
            } else {
                store.joins = payload;
            }
        },
        joinSet: {
            reducer: (
                state,
                {
                    payload,
                    meta,
                }: PayloadAction<
                    boolean,
                    string,
                    {index?: number; side?: JoinSide}
                >
            ) => {
                if (typeof meta.side != 'undefined') {
                    state.joins.forEach((join, index) => {
                        if (join.side == meta.side) {
                            if (index == meta.index) {
                                join.selected = payload;
                            } else {
                                join.selected = false;
                            }
                        }
                    });
                }
            },
            prepare: (selected: boolean, index: number, side: JoinSide) => {
                return {
                    payload: selected,
                    meta: {
                        index,
                        side,
                    },
                };
            },
        },
        scaleSet: (store, {payload}: PayloadAction<number>) => {
            store.scale = payload;
        },
        typeSet: {
            reducer: (store, {payload}: PayloadAction<BenchtopType>) => {
                store.type = payload;
            },
            prepare: (payload: BenchtopType, initBench: boolean) => {
                return {payload, meta: {initBench}};
            },
        },
        materialTypeSet: (
            store,
            {payload}: PayloadAction<BenchtopMaterialType>
        ) => {
            store.materialType = payload;
        },
        coordinatesSet: (store, {payload}: PayloadAction<Point[]>) => {
            store.coordinates = payload;
        },
        cornersSet: (store, {payload}: PayloadAction<Corner[]>) => {
            store.corners = payload;
        },
        cornerSet: genericReducer<Corner>((state, payload, meta) => {
            if (meta && meta.hasOwnProperty('index')) {
                state.corners[meta.index] = payload;
            }
        }),
        pathsSet: {
            reducer: (
                store,
                {payload}: PayloadAction<Path[], string, {updateJoin: boolean}>
            ) => {
                store.paths = payload;
            },
            prepare: (payload: Path[], updateJoin?: boolean) => {
                // NOTE: Did this because i could not figure out
                // how to make updateJoin optional up here in the parameter
                const doUpdate =
                    typeof updateJoin === 'undefined' ? false : updateJoin;

                return {
                    payload,
                    meta: {updateJoin: doUpdate},
                };
            },
        },
        materialSet: (store, {payload}: PayloadAction<BenchtopMaterial>) => {
            store.material = payload;
        },
        dimensionSet: (store, {payload}: PayloadAction<number[]>) => {
            store.dimension = payload;
        },
        dimensionErrorSet: {
            reducer: (
                store,
                {payload, meta}: PayloadAction<string, string, {index: number}>
            ) => {
                if (meta?.index >= 0) {
                    store.dimensionError[meta.index] = payload;
                }
            },
            prepare: (messages: string, index: number) => ({
                payload: messages,
                meta: {index},
            }),
        },
        buttJoinPartErrorSet: (
            store,
            {payload}: PayloadAction<ButtJoinPartError>
        ) => {
            const index = store.buttJoinPartErrors.findIndex(
                (error) =>
                    error.index == payload.index && error.side == payload.side
            );

            if (index > -1) {
                store.buttJoinPartErrors[Number(index)] = payload;
            } else {
                store.buttJoinPartErrors.push(payload);
            }
        },
        buttJoinPartsErrorClear: (store) => {
            store.buttJoinPartErrors = [];
        },
        dimensionErrorsSet: (store, {payload}: PayloadAction<string[]>) => {
            store.dimensionError = payload;
        },
        centerSet: (store, {payload}: PayloadAction<number[]>) => {
            store.center = payload;
        },
        sideSet: {
            reducer: (
                store,
                {
                    payload,
                    meta,
                }: PayloadAction<
                    number,
                    string,
                    {index: number; updateButtJoin?: boolean}
                >
            ) => {
                if (typeof store.dimension[meta.index] !== 'undefined') {
                    store.dimension[meta.index] = payload;
                }
            },
            prepare: (
                side: number,
                index: number,
                updateButtJoin?: boolean
            ) => {
                return {payload: side, meta: {index, updateButtJoin}};
            },
        },
        profiledSet: {
            reducer: (
                store,
                {
                    payload,
                    meta,
                }: PayloadAction<
                    Edge,
                    string,
                    {index: number; profile: BenchtopEdgeProfile}
                >
            ) => {
                if (typeof store.paths[meta.index] !== 'undefined') {
                    store.paths[meta.index].edged = payload;
                    if (meta.profile != null) {
                        store.paths[meta.index].profile = meta.profile.id;
                    } else {
                        store.paths[meta.index].profile = 0;
                    }
                }
            },
            prepare: (
                edge: Edge,
                profile: BenchtopEdgeProfile = null,
                index: number,
                side: Side,
                profiles?: BenchtopEdgeProfile[]
            ) => {
                return {payload: edge, meta: {index, profile, side, profiles}};
            },
        },
        profileReset: (store) => {
            if (store.paths) {
                store.paths.forEach((path) => {
                    path.edged = Edge.NOT_EDGED;
                    path.profile = 0;
                });
            }
        },
        allButtJoinsSet: (store, {payload}: PayloadAction<Join[]>) => {
            store.joins.push(...payload);
        },
        buttJoinsSet: {
            reducer: (
                store,
                {payload, meta}: PayloadAction<Join[], string, {side: Side}>
            ) => {
                const {side: benchSide} = meta;

                store.joins = store.joins.filter(
                    (join) => join.benchSide != benchSide
                );

                payload.forEach((join) => {
                    join.benchSide = benchSide;
                    store.joins.push(join);
                });
            },
            prepare: (parts: Join[], side: Side) => {
                return {
                    payload: parts,
                    meta: {side},
                };
            },
        },
        buttJoinSet: {
            reducer: (
                store,
                {payload, meta}: PayloadAction<Join, string, {index: number}>
            ) => {
                const {index} = meta;
                store.joins[Number(index)] = payload;
            },
            prepare: (join: Join, index: number) => {
                return {
                    payload: join,
                    meta: {index},
                };
            },
        },
        buttJoinErrorSet: (
            store,
            {payload}: PayloadAction<{message: string; benchSide: Side}>
        ) => {
            const {message, benchSide} = payload;

            store.joins = store.joins.map((join) => {
                if (join.benchSide === benchSide) {
                    return {...join, error: message};
                } else {
                    return join;
                }
            });
        },
        clearJoins: (store) => {
            store.joins = [];
        },
        clearAll: (store) => {
            store.center = [0, 0];
            store.scale = 1;
            store.cornerError = [];
            store.dimensionError = [];
            store.defaultMaterialLoaded = false;
            store.defaultEdgeProfileLoaded = false;
            store.buttJoinPartErrors = [];
            delete store.materialType;
            delete store.type;
            delete store.material;
            delete store.dimension;
            delete store.paths;
            delete store.corners;
            delete store.joins;
            delete store.thickness;
            delete store.edgeProfile;
            delete store.coordinates;
            delete store.variationRequest;
        },
    },
});

export const selectDefaultEdgeProfileLoaded = (store: AppState) =>
    store.btm.defaultEdgeProfileLoaded;
export const selectDefaultMaterialLoaded = (store: AppState) =>
    store.btm.defaultMaterialLoaded;
export const selectMaterialIsLoading = (store: AppState) =>
    store.btm.materialLoading;
export const selectType = (store: AppState) => store.btm.type;
export const selectMaterialType = (store: AppState) => store.btm.materialType;
export const selectMaterial = (store: AppState) => store.btm.material;
export const selectDimension = (store: AppState) => store.btm.dimension;
export const selectMessage = (store: AppState) => store.btm.dimensionError;
export const selectCenter = (store: AppState) => store.btm.center;
export const selectScale = (store: AppState) => store.btm.scale;
export const selectPaths = (store: AppState) => store.btm.paths;
export const selectCoordinates = (store: AppState) => store.btm.coordinates;
export const selectCorners = (store: AppState) => store.btm.corners;
export const selectJoins = (store: AppState) => store.btm.joins;
export const selectThickness = (store: AppState) => store.btm.thickness;
export const selectEdgeProfile = (store: AppState) => store.btm.edgeProfile;
export const selectVariationRequest = (store: AppState) =>
    store.btm.variationRequest;
export const selectCornerErrors = (store: AppState) => store.btm.cornerError;
export const selectMinButtJoinPartSize = (store: AppState) =>
    store.btm.minButtJoinPartSize;
export const selectButtJoinPartErrors = (store: AppState) =>
    store.btm.buttJoinPartErrors;
export const selectTopOffset = (store: AppState) => store.btm.topOffset;

export const selectButtJoinBySide = createSelector(
    [selectJoins, (state: AppState, side: Side) => side],
    (joins, side) => {
        return joins
            .filter((join) => join.joinType == JoinType.BUTT_JOIN)
            .filter((join) => join.benchSide == side);
    }
);

export const selectDimensionBySide = createSelector(
    [selectDimension, (state: AppState, side: Side) => side],
    (dimension, side) => {
        // This is only for U shaped bench
        if (dimension && side == Side.I) {
            const dimensionI = dimension[1] - dimension[3];

            if (dimensionI % 1 === 0) {
                return dimensionI;
            }

            return parseFloat(dimensionI.toFixed(2));
        }

        return dimension && dimension[Number(side)];
    }
);

export const selectMessageBySide = createSelector(
    [selectMessage, (state: AppState, side: Side) => side],
    (dimensionErrors, side) => {
        return dimensionErrors && dimensionErrors[Number(side)];
    }
);

export const selectButtJoinPartError = createSelector(
    [
        selectButtJoinPartErrors,
        (state: AppState, side: Side) => side,
        (state: AppState, side: Side, index: number) => index,
    ],
    (errors, side, index) => {
        return errors.find(
            (error) => error.index == index && error.side == side
        );
    }
);

export const isThicknessSelected = createSelector(
    [
        (state: AppState) => state.btm,
        (state: AppState, thickness: number) => thickness,
    ],
    (btm, thickness) => {
        return btm.thickness == thickness;
    }
);

export const isProfileSelected = createSelector(
    [
        (state: AppState) => state.btm,
        (state: AppState, profile: number) => profile,
    ],
    (btm, profile) => {
        return btm?.edgeProfile?.id == profile;
    }
);

export const selectJoinSides = createSelector(
    [(state: AppState) => state.btm],
    (btm) => {
        if (btm.joins && btm.joins.length > 0) {
            const groupedJoins = groupBy(
                btm.joins.filter((join) => join.joinType != JoinType.BUTT_JOIN),
                'side'
            );

            return Object.keys(groupedJoins);
        }
    }
);

export const selectSelectedJoins = createSelector(
    [(state: AppState) => state.btm],
    (btm) => {
        if (btm.joins && btm.joins.length > 0) {
            return btm.joins.filter((join) => join.selected);
        }
    }
);

export const selectJoinsBySide = createSelector(
    [(state: AppState) => state.btm, (state: AppState, side: string) => side],
    (btm, side) => {
        if (btm?.joins.length) {
            return btm.joins
                .map((join, index) => ({...join, index}))
                .map((join) => {
                    switch (join.joinType) {
                        case JoinType.DOG_LEG_MITRE:
                        case JoinType.FULL_MITRE:
                            join.image = 'join_diagonal.svg';
                            break;
                        case JoinType.MASONS_MITRE:
                            if (
                                join.orientation == JoinOrientation.HORIZONTAL
                            ) {
                                join.image = 'join_horizontal.svg';
                            } else {
                                join.image = 'join_vertical.svg';
                            }
                            break;
                    }

                    return join;
                })
                .filter(
                    (join) =>
                        join.side == parseInt(side) &&
                        join.joinType != JoinType.BUTT_JOIN
                );
        }
    }
);

export const selectCornerError = createSelector(
    [(state: AppState) => state.btm, (state: AppState, index: number) => index],
    (btm, index) => {
        if (btm?.cornerError) {
            const error = btm.cornerError.find((error) => error.index == index);

            if (error) {
                return error;
            }
        }
    }
);

export const {
    materialSet,
    typeSet,
    materialTypeSet,
    dimensionSet,
    sideSet,
    centerSet,
    scaleSet,
    pathsSet,
    clearAll,
    coordinatesSet,
    cornersSet,
    cornerSet,
    joinsSet,
    joinSet,
    thicknessSet,
    profiledSet,
    edgeProfileSet,
    variationRequestSet,
    profileReset,
    dimensionErrorsSet,
    dimensionErrorSet,
    materialLoadingSet,
    defaultMaterialLoadedSet,
    defaultThicknessSet,
    defaultEdgeProfileLoadedSet,
    cornerErrorSet,
    buttJoinsSet,
    buttJoinSet,
    minButtJoinPartSizeSet,
    clearJoins,
    buttJoinPartErrorSet,
    topOffsetSet,
    buttJoinPartsErrorClear,
    buttJoinErrorSet,
    allButtJoinsSet,
} = btmSlice.actions;

export default btmSlice.reducer;
