import {cloneDeep} from 'lodash';
import {JobStatusDefaults, JobStatusDefaultsSimplified} from 'shared/JobStatus';
import {appApi} from 'store/dashboard/appApi';

export interface PreferencesData {
    defaultJobPropertiesPdf: number;
    allowOrderAcknowledgementPdf: boolean;
    showJobPropertiesTablePdf: boolean;
    showOrderAcknowledgementPdf: boolean;
    permitGlassSubPanel: boolean;
    permitCoupons: boolean;
    permitCustomerSelfRegistration: boolean;
    allowFileUpload: boolean;
    doorHangReversal: boolean;
    displayEstimatedCompletion: boolean;
    maxAgeJobPrice: number;
    createJobLandingPage: number;
    defaultEmailNotificationType: number;
    allowDirectLogin: boolean;
    isEnabledJwt: boolean;
    tokenHeaderName: string;
    keys: string;
    tokenIssuer: string;
    tokenAudience: string;
    customerMatchRule: string;
    manufacturerTokenRule: string;
    isEnabledAuth0Validation: boolean;
    parentApplicationName: string;
    parentApplicationUrl: string;
    simpleJobWorkflow: number;
    allowDeliveryDateRequest: boolean;
    minimumLeadTime: number;
    requestedDeliveryDateFrequency: number;
    allowNotchesAndClips: boolean;
}

export interface ManufacturerPreferences {
    preferences: PreferencesData;
    success: number;
    manufacturerName: string;
    createJobLandingPageOptions: number[];
    jobStatuses: JobStatusData[];
    isBenchtopEnabled: boolean;
}

export interface SavePreferencesRequest {
    permitGlassSubPanel: boolean;
    permitCoupons: boolean;
    permitSelfRegistration: boolean;
    permitFileUpload: boolean;
    doorHangReversal: boolean;
    displayEstimatedCompletion: boolean;
    maxAgeJobPrice: string | number;
    jobDetailsPDF?: boolean;
    orderAcknowledgementPDF?: boolean;
    primaryJobPDF?: number;
    createJobLandingPage: number;
    defaultEmailNotificationType: number;
    allowDirectLogin: boolean;
    isEnabledJwt: boolean;
    tokenHeaderName: string;
    keys: string;
    tokenIssuer: string;
    tokenAudience: string;
    customerMatchRule: string;
    manufacturerTokenRule: string;
    isEnabledAuth0Validation: boolean;
    parentApplicationName: string;
    parentApplicationUrl: string;
    jobStatuses: JobStatusData[];
    allowDeliveryDateRequest: boolean;
    minimumLeadTime: string | number;
    requestedDeliveryDateFrequency: number;
    allowNotchesAndClips: boolean;
}

export interface JobStatusData {
    status: number;
    custom_label?: string;
}

/**
 * Sanitise the list of statuses passed so it contains all expected statuses
 *
 * @param {JobStatusData[]} jobStatuses Statuses to sanitise
 * @param {Number} simpleJobWorkflow
 * @return {JobStatusData[]}
 */
export const mapJobStatusDefaults = (
    jobStatuses: JobStatusData[] | null,
    simpleJobWorkflow: number
): JobStatusData[] => {
    jobStatuses ??= [];

    const defaultJobStatuses = simpleJobWorkflow
        ? JobStatusDefaultsSimplified
        : JobStatusDefaults;

    return defaultJobStatuses.map(
        (status) =>
            jobStatuses.find((item) => item.status == status) ?? {
                status,
                custom_label: null,
            }
    );
};

const preferencesApi = appApi.injectEndpoints({
    endpoints: (build) => ({
        getManufacterPreferences: build.query<ManufacturerPreferences, void>({
            query: () => 'manufacturer/preferences',
            providesTags: ['ManufacturerPreferences'],
            transformResponse: (responseData: ManufacturerPreferences) => {
                const {
                    jobStatuses,
                    preferences: {simpleJobWorkflow},
                } = responseData;
                const newResponseData = cloneDeep(responseData);
                newResponseData['jobStatuses'] = mapJobStatusDefaults(
                    jobStatuses,
                    simpleJobWorkflow
                );
                return newResponseData;
            },
        }),
        saveManufacturerPreferences: build.mutation<
            void,
            SavePreferencesRequest
        >({
            query(data) {
                const request = {
                    permitGlassSubPanel: data.permitGlassSubPanel,
                    permitCoupons: data.permitCoupons ? 1 : 0,
                    permitSelfRegistration: data.permitSelfRegistration ? 1 : 0,
                    permitFileUpload: data.permitFileUpload ? 1 : 0,
                    doorHangReversal: data.doorHangReversal ? 1 : 0,
                    displayEstimatedCompletion: data.displayEstimatedCompletion
                        ? 1
                        : 0,
                    maxAgeJobPrice: data.maxAgeJobPrice,
                    jobDetailsPDF: data?.jobDetailsPDF,
                    orderAcknowledgementPDF: data?.orderAcknowledgementPDF,
                    primaryJobPDF: data?.primaryJobPDF,
                    createJobLandingPage: data.createJobLandingPage,
                    defaultEmailNotificationType:
                        data.defaultEmailNotificationType,
                    allowDirectLogin: data.allowDirectLogin,
                    isEnabledJwt: data?.isEnabledJwt,
                    tokenHeaderName: data?.tokenHeaderName,
                    keys: data?.keys,
                    tokenIssuer: data?.tokenIssuer,
                    tokenAudience: data?.tokenAudience,
                    customerMatchRule: data?.customerMatchRule,
                    manufacturerTokenRule: data?.manufacturerTokenRule,
                    isEnabledAuth0Validation: data?.isEnabledAuth0Validation,
                    parentApplicationName: data?.parentApplicationName,
                    parentApplicationUrl: data?.parentApplicationUrl,
                    default_job_status_names: data.jobStatuses,
                    allowDeliveryDateRequest: data.allowDeliveryDateRequest,
                    minimumLeadTime: data?.minimumLeadTime,
                    requestedDeliveryDateFrequency:
                        data?.requestedDeliveryDateFrequency,
                    allowNotchesAndClips: data?.allowNotchesAndClips ? 1 : 0,
                };
                return {
                    url: `manufacturer/preferences`,
                    method: 'POST',
                    formData: true,
                    body: request,
                };
            },
            invalidatesTags: (result, error) =>
                error ? [] : ['ManufacturerPreferences'],
        }),
    }),
});

export const {
    useGetManufacterPreferencesQuery,
    useSaveManufacturerPreferencesMutation,
} = preferencesApi;
